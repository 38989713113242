import React from 'react'
import styled from 'styled-components'

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Buttons,
  Columns,
  Divider,
  Inline,
  List,
  ListItem,
  Paragraph,
  PostponeIcon,
  Strong,
  XIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { NoteItem } from '../../components/NoteItem'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { ZoomedExample } from '../../components/ZoomedExample'
import { MultiPageFormNavigationExample } from '../../examples/react/pages/MultiPageFormNavigationExample'
import { Link } from '../../components/Link'

const StyledMultiPageFormNavigation = styled(MultiPageFormNavigationExample)`
  [data-id='statusbar'] {
    position: relative;
    transform: none;
  }
`
// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Monisivuisen lomakkeen navigaatio">
    <Section title="Periaatteet">
      <Paragraph noMargin>
        Monisivuisissa lomakkeissa sivujen välillä navigoiminen mahdollistetaan
        käyttäjälle kahdella tapaa:
      </Paragraph>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          <Strong>Lomakkeen kaikki sivut listaavalla sivunavigaatiolla</Strong>{' '}
          (
          <Code>
            <Link page="SideSteps" />
          </Code>
          ), jonka avulla käyttäjä
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              saa yleiskuvan siitä, mihin vaiheisiin tai sisältökokonaisuuksiin
              lomakkeen täyttäminen jakautuu ja missä vaiheessa lomakkeen
              täyttämistä hän on,
            </ListItem>
            <ListItem>
              voi navigoida lomakesivujen välillä vapaasti, ellei lomake ole
              tarkoitus täyttää sivu kerrallaan tietyssä järjestyksessä (kts.{' '}
              <Link page="Monisivuinen lomake" /> -patternin tilat ja
              muunnelmat).
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Strong>Edellinen/Seuraava -painikkeilla</Strong>, joiden avulla
          käyttäjä voi edetä lomakkeen vaiheiden välillä lineaarisesti.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Edellinen/Seuraava -painikkeiden avulla siirrytään vain lomakkeen
              sivujen välillä. Painikkeita ei käytetä lomakkeen ulkopuoliselle
              sivulle (esim. lomaketta edeltävään näkymään) navigoimiseen.
            </ListItem>
            <ListItem>
              Estetyt sivut hypätään välistä painikkeilla navigoitaessa.
            </ListItem>
          </List>
        </ListItem>
      </List>
    </Section>
    <Section title="Saavutettavuus">
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Kun monisivuiselle lomakkeelle saavutaan ensimmäisen kerran, lähdetään
          lukemaan sivua alusta.
        </ListItem>
        <ListItem>
          Jos käyttäjä haluaa käyttää <Code>SkipLink</Code>iä, fokus siirtyy
          sivun sisällön alkuun. Ensimmäisenä luetaan siis <Code>SideStep</Code>
          in sisältö ja sen jälkeen sivun 1-tason otsikko.
        </ListItem>
        <ListItem>
          Kun lomakkeella navigoidaan Seuraava- tai Edellinen -painikkeilla,
          fokus astetaan sivun 1-tason otsikkoon (ei <Code>SideStep</Code>iin,
          jotta vältetään toistoa). Fokus siirretään sivun otsikkoon{' '}
          <Code>
            <Link to="/muut/focus/#fokus-otsikossa">Focus</Link>
          </Code>
          -komponentilla.
        </ListItem>
        <ListItem>
          Huomioi myös, että 1-tason otsikon teksti vaihtuu jokaisella
          monisivuisen lomakkeen sivulla. Sivun otsikko on aina sama kuin
          kyseisen sivun nimi <Code>SideStep</Code>ssä.
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <NoteItem
        bullet="1"
        title={
          <>
            Sivunavigaatio (
            <Code>
              <Link page="SideSteps" />
            </Code>
            )
          </>
        }
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Lomakkeen sivunavigaatio sijoitetaan työpöytänäkymän vasempaan
            laitaan (<Code>{'<Columns layout="sidebar">'}</Code>
            ).
          </ListItem>
          <ListItem>
            <Strong>
              Sivunavigaation ensimmäisenä elementtinä näytetään koko lomakkeen
              yhteinen pääotsikko
            </Strong>{' '}
            ja tarvittaessa tarkentava alaotsikko.
          </ListItem>
          <ListItem>
            <Strong>
              Otsikon alla listataan lomakesivut erillisinä vaiheina (
              <Code>{'<SideStep>'}</Code>)
            </Strong>
            , joissa näytetään kunkin lomakesivun otsikko ja tila.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Vaiheiden avulla käyttäjä voi navigoida lomakkeen sivujen
                välillä vapaasti, ellei lomake ole tarkoitus täyttää tietyssä
                järjestyksessä.
              </ListItem>
              <ListItem>
                Sivut voivat olla seuraavissa tiloissa:
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>
                    <Strong>Perustila:</Strong> käyttäjä ei ole käynyt
                    lomakesivulla, tai käydessään ei ole täyttänyt kaikkia
                    pakollisia kenttiä ja sivulla ei ole virheitä.
                  </ListItem>
                  <ListItem>
                    <Strong>Aktiivinen</Strong> (<Code>status='active'</Code>):
                    sivu, jota käyttäjä tällä hetkellä tarkastelee.
                  </ListItem>
                  <ListItem>
                    <Strong>
                      Valmis (<Code>status='complete'</Code>):
                    </Strong>{' '}
                    sivu, jolle käyttäjä on täyttänyt kaikki pakolliset tiedot.
                  </ListItem>
                  <ListItem>
                    <Strong>
                      Estetty (<Code>status='disabled'</Code>):
                    </Strong>{' '}
                    sivu, jolle käyttäjä ei voi navigoida. Sivuja voidaan
                    esimerkiksi estää, koska käyttäjä ei ole täyttänyt sitä
                    edeltäville sivuille tiettyjä tietoja tai valintoja.
                  </ListItem>
                  <ListItem>
                    <Strong>
                      Virheellinen (<Code>status='error'</Code>):
                    </Strong>{' '}
                    sivu, joka ei ole läpäissyt lomakkeen virheenkäsittelyä.
                    Käyttäjä on joko täyttänyt sivulle tietoa virheellisessä
                    muodossa tai jättänyt täyttämättä pakollista tietoa (kts.{' '}
                    <Link page="Lomakkeen virheenkäsittely" /> -pattern).
                  </ListItem>
                </List>
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <Strong>
              Monimutkaisen lomakkeen rakennetta voidaan selkiyttää jakamalla
              vaiheita alasivuiksi
            </Strong>{' '}
            (<Code>subSteps</Code>).
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Alasivut ovat toiminnallisesti kuten muutkin lomakkeen sivut,
                mutta sivunavigaatiossa ne ryhmitellään yhden päätason vaiheen
                alle.
              </ListItem>
              <ListItem>
                Oletuksena päätason vaiheella ei ole omaa sivua, vaan
                edelliseltä sivulta navigoitaessa siirrytään suoraan sen
                ensimmäiselle alasivulle.
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>
                    Tarvittaessa päätaso voi olla myös oma sivunsa, jolla
                    erimerkiksi tarjotaan kootusti tietoa alatason vaiheiden
                    täyttämisestä tai lomakekenttiä päätason tiedon
                    syöttämiseksi.
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                Päätason vaiheen tila riippuu sen alasivujen tilasta: päätason
                tila on valmis, kun kaikki alasivut ovat valmiita ja
                virheellinen, jos yksikin alasivu on virheellinen.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <Strong>
              Mobiilikoossa sivunavigaatio piilotetaan ja sen sijasta näytetään
              aktiivinen lomakesivu -painike
            </Strong>
            , josta sivunavigaatio avautuu.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Lomakkeen otsikko näytetään painikkeen yläpuolella,
                sisältöalueen ensimmäisenä elementtinä.
              </ListItem>
              <ListItem>
                Kun lomakesivua selataan alaspäin niin, aktiivinen
                lomakesivu-painike jää piiloon, näytetään sen sijaan pienempi
                sivunavigaation <Strong>avaus-/sulkupainike</Strong>.
              </ListItem>
              <ListItem>
                Sivunavigaatio sulkeutuu siirryttäessä valitulle sivulle,
                painettaessa sivunavigaation{' '}
                <Strong>avaus-/sulkupainiketta</Strong> tai ulkopuolelle jäävää
                tummennettua aluetta.
              </ListItem>
            </List>
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="2"
        title={
          <>
            Edellinen/Seuraava -painikkeet (
            <Code>
              <Link page="Button" />
            </Code>
            )
          </>
        }
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Lomakkeen sivujen välillä navigoivat Edellinen ja Seuraava
            -painikkeet sijoitetaan lomakkeen alalaitaan, sisältöalueen jälkeen,
            ennen lomakkeen toimintoja.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Navigaatiopainikkeet erotetaan yllä olevasta lomakkeen
                sisältöalueesta:
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>
                    <Strong>Väliviivalla</Strong> (
                    <Code>
                      <Link page="Divider" />
                    </Code>
                    ), jos sisältöalueen viimeisenä elementtinä on esimerkiksi
                    painike tai muu irrallinen elementti.
                  </ListItem>
                  <ListItem>
                    <Strong>Marginaalilla</Strong> (<Code>xl</Code> ={' '}
                    <Code>2rem</Code>), jos sisältöalueen viimeisenä elementtinä
                    on lomake-osio, jolla on taustalaatikko. Taustalaatikko
                    riittää erottamaan sisältöalueen ja lomakenavigaation
                    toisistaan, eikä väliviivaa tarvita.
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                Alla olevista lomakkeen toiminnoista navigaatiopainikkeet
                erotetaan aina väliviivalla (
                <Code>
                  <Link page="Divider" />
                </Code>
                ).
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <Strong>Seuraava-painike</Strong>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                <Code>
                  {'<Button iconRight={<ArrowRight />}>Seuraava</Button>'}
                </Code>
              </ListItem>
              <ListItem>Tasattu sisältöalueen oikeaan laitaan.</ListItem>
            </List>
          </ListItem>
          <ListItem>
            <Strong>Edellinen-painike</Strong>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                <Code>
                  {
                    '<Button iconLeft={<ArrowLeft />} variant="outline>Edellinen</Button>'
                  }
                </Code>
              </ListItem>
              <ListItem>Tasattu sisältöalueen vasempaan laitaan.</ListItem>
            </List>
          </ListItem>
        </List>
      </NoteItem>
      <ZoomedExample notes={[{ id: '1' }, { id: '2' }]}>
        <StyledMultiPageFormNavigation />
      </ZoomedExample>
    </Section>
    <Section title="Tilat ja muunnelmat">
      <SectionHeading>
        Navigaatiopainikkeet lomakkeen ensimmäisellä sivulla
      </SectionHeading>
      <List variant="unordered">
        <ListItem>
          Lomakkeen ensimmäisellä sivulla ei näytetä Edellinen-painiketta.
        </ListItem>
      </List>
      <ZoomedExample>
        <Columns layout="single">
          <div>
            <Inline justifyContent="end">
              <Button iconRight={<ArrowRightIcon />}>Seuraava</Button>
            </Inline>
            <Divider />
            <Buttons
              align="center"
              noMargin
            >
              <Button
                iconLeft={<XIcon />}
                variant="plain"
              >
                Keskeytä
              </Button>
              <Button
                iconLeft={<PostponeIcon />}
                variant="plain"
              >
                Jatka myöhemmin
              </Button>
            </Buttons>
          </div>
        </Columns>
      </ZoomedExample>
      <SectionHeading>
        Navigaatiopainikkeet lomakkeen viimeisellä sivulla
      </SectionHeading>
      <List variant="unordered">
        <ListItem>
          Lomakkeen viimeisellä sivulla ei näytetä Seuraavaa-painiketta.
        </ListItem>
      </List>
      <ZoomedExample>
        <Columns layout="single">
          <div>
            <Inline justifyContent="start">
              <Button
                iconRight={<ArrowLeftIcon />}
                variant="outline"
              >
                Edellinen
              </Button>
            </Inline>
            <Divider />
            <Buttons
              align="center"
              noMargin
            >
              <Button
                iconLeft={<XIcon />}
                variant="plain"
              >
                Keskeytä
              </Button>
              <Button
                iconLeft={<PostponeIcon />}
                variant="plain"
              >
                Jatka myöhemmin
              </Button>
              <Button color="secondary">Lähetä</Button>
            </Buttons>
          </div>
        </Columns>
      </ZoomedExample>
    </Section>
  </Content>
)

export default Page
